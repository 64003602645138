import React from 'react'
import styled from 'styled-components'
import Container from '../ui/Container'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from 'gatsby'

const Wrapper = styled.div`
    background: linear-gradient(114.43deg, #9CB0FF 42.71%, #D4DBF5 100%);
    text-align: center;
    padding: 0 0 70px;

    @media(min-width: 768px) {
        text-align: left;
        padding: 200px 20px;
    }
`

const FirstSection = styled.div`
    @media(min-width: 768px) {
        width: 50%;
    }
`

const SecondSection = styled.div`
    @media(min-width: 768px) {
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
`
const Sections = styled.div`
    @media(min-width: 768px) {
        display: flex;
    }
`

const Phones = styled(Img)`
    width: 80%;
    margin: 60px auto 0;
    @media(min-width: 768px) {
        width: 100%;
        margin: 0;
    }
`
const Title = styled.h4`
    padding: 60px 5px 0;
    font-style: italic;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 28px;
    line-height: 55px;
    letter-spacing: -1px;
        
    @media(min-width: 768px) {
        font-size: 40px;
    }
`

function Cta() {

    const { phones } = useStaticQuery(graphql`
    query {
      phones: file(relativePath: { eq: "3-phones.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    return <Wrapper>
        <Container>
            <Sections>
                <FirstSection>
                    <Title>Give your workforce the benefit of Cerebral.</Title>
                </FirstSection>
                <SecondSection>
                    <Phones fluid={phones.childImageSharp.fluid} />
                </SecondSection>
            </Sections>
        </Container>
    </Wrapper>
}

export default Cta