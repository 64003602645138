import React from 'react'
import ItalicHeading from '../ui/ItalicHeading'
import styled from 'styled-components'
import Container from '../ui/Container'
import Waves from '../ui/Waves'
import firstImage from '../../images/howcerebralworks/1.png'
import secondImage from '../../images/howcerebralworks/2.png'
import thirdImage from '../../images/howcerebralworks/3.png'
import fourthImage from '../../images/howcerebralworks/4.png'
import fifthImage from '../../images/howcerebralworks/5.png'
import sixthImage from '../../images/howcerebralworks/6.png'
import SectionWrapper from '../ui/SectionWrapper'
import SecondaryHeading from '../ui/SecondaryHeading'

const GettingStartedItem = styled.li`
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding: 20px 0;
    z-index: 1;
    position: relative;
`
const ItemIcon = styled.div`
    background-color: #AABAF9;
    border: 5px solid #6786FF;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 20px;
`
const ItemContent = styled.div`
    flex: 1;
`
const ItemTitle = styled.h4`
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
`
const ItemDescription = styled.p`
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 30px;
`
const CurvedLines = styled.div`
    height: 70px;
    position: relative;
    overflow: hidden;
`
const CurvedLineLeft = styled.div`
    border-bottom: 5px solid #AABAF9;
    border-left: 5px solid #AABAF9; 
    border-radius: 30px;
    border-bottom-right-radius: 0;
    position: absolute;
    height: 100%;
    width: 50%;
    left: 27.4px;
    top: -45px;
`
const CurvedLineRight = styled.div`
    border-top: 5px solid #AABAF9;
    border-right: 5px solid #AABAF9; 
    border-radius: 30px;
    border-top-left-radius: 0;
    position: absolute;
    height: 100%;
    width: 50%;
    right: 27.49px;
    bottom: -30px;
`
const GettingStartedHeading = styled.div`
    text-align: left;
    padding: 0 10px;
    & ${ItalicHeading} {
        margin: 0;
        font-size: 25px;
        display: inline-block;
        @media (min-width: 600px) {
            font-size: 28px;
        }
    }
`
const EveryMonthHeading = styled.div`
    text-align: right;
    padding: 0 10px;
     & ${ItalicHeading} {
        margin: 0;
        font-size: 25px;
        display: inline-block;
        background-color: #85ABEE;
        @media (min-width: 600px) {
            font-size: 28px;
        }
    }
`
const GettingStartedList = styled.ul`
    padding-top: 30px;
    position: relative;
    padding-right: 26px;

    &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 27.4px;
        width: 5px;
        background: #AABAF9;
        height: 100%;
        z-index: 0;
    }
`
const EveryMonthList = styled(GettingStartedList)`
    
    padding-right: unset;
    padding-left: 26px;

    &::before {
        left: unset;
        right: 27.4px;
        background: linear-gradient(180deg,#85ABEE 70%,rgba(133,171,238,0) 100%);
    }

    ${ItemIcon} {
        order: 1;
        background: #85ABEE;
    }
`
const Diagram = styled.div`
    max-width: 815px;
    margin: 100px auto 0;
`
const Wrapper = styled(SectionWrapper)`
    position: relative;
    background: linear-gradient(180deg, #CDF4FD 0%, #F0FCFE 100%);
    text-align: center;

    ${SecondaryHeading} {
        margin: 50px auto 0;
        width: 300px;
    }

    ${Container} {
        padding: 0 0; 
    }

    @media (min-width: 600px) {

        ${Container} {
            padding: 0 12px; 
        }

        ${GettingStartedList} {
            &::before {
                left: 78px;
            }

            ${GettingStartedItem}:nth-child(1) ${ItemIcon}{
                background-image: url(${firstImage});
            }   

            ${GettingStartedItem}:nth-child(2) ${ItemIcon}{
                background-image: url(${secondImage});
            }   

            ${GettingStartedItem}:nth-child(3) ${ItemIcon}{
                background-image: url(${thirdImage});
            }

            ${ItemIcon} {
                margin-left: 0;
            }
        }

        ${EveryMonthList} {
            &::before {
                left: unset;
                right: 78px;
            }

            ${GettingStartedItem}:nth-child(1) ${ItemIcon}{
                background-image: url(${fourthImage});
            }   

            ${GettingStartedItem}:nth-child(2) ${ItemIcon}{
                background-image: url(${fifthImage});
            }   

            ${GettingStartedItem}:nth-child(3) ${ItemIcon}{
                background-image: url(${sixthImage});
            }  

            ${ItemIcon} {
                margin-right: 0;
                margin-left: 20px;
            }

            ${GettingStartedItem} {
                justify-content: flex-end;
            }
        }

        ${ItemIcon} {
            width: 160px;
            height: 160px;
            border: none;
            border-radius: unset;
            background-color: unset;
            background-size: 160px;
        }

        ${CurvedLines} {
            margin: 0 52px;
        }

        ${EveryMonthHeading}, ${GettingStartedHeading} {
            padding: 0 70px;
        }

        ${ItemContent} {
            margin-top: 30px;
            flex: unset;
            max-width: 300px;
        }

        ${ItemDescription} {
            margin-top: 30px;
        }
    }
`

export default function BusinessHowCerebralWorks() {

    return <Wrapper>
        <Waves flip={true} />
        <Container>
            <SecondaryHeading>How do your employees use Cerebral?</SecondaryHeading>
            <Diagram>
                <GettingStartedHeading><ItalicHeading>Getting Started</ItalicHeading></GettingStartedHeading>
                <GettingStartedList>
                    <GettingStartedItem>
                        <ItemIcon />
                        <ItemContent>
                            <ItemTitle>1. Fill out a short online form</ItemTitle>
                            <ItemDescription>Just a few short questions to help us understand your symptoms.</ItemDescription>
                        </ItemContent>
                    </GettingStartedItem>
                    <GettingStartedItem>
                        <ItemIcon />
                        <ItemContent>
                            <ItemTitle>2. Hop on a video call with your provider</ItemTitle>
                            <ItemDescription>Let’s chat. Your prescribing provider will help determine your treatment plan.</ItemDescription>
                        </ItemContent>
                    </GettingStartedItem>
                    <GettingStartedItem>
                        <ItemIcon />
                        <ItemContent>
                            <ItemTitle>3. Meet your Care Manager </ItemTitle>
                            <ItemDescription>Your Care Manager supports you along your journey—say hello on a video call.</ItemDescription>
                        </ItemContent>
                    </GettingStartedItem>
                </GettingStartedList>
                <CurvedLines>
                    <CurvedLineLeft />
                    <CurvedLineRight />
                </CurvedLines>
                <EveryMonthHeading><ItalicHeading>Every Month</ItalicHeading></EveryMonthHeading>
                <EveryMonthList>
                    <GettingStartedItem>
                        <ItemIcon />
                        <ItemContent>
                            <ItemTitle>1. Get your medication mailed monthly</ItemTitle>
                            <ItemDescription>If prescribed, we’ll ship your medication to your door each month.</ItemDescription>
                        </ItemContent>
                    </GettingStartedItem>
                    <GettingStartedItem>
                        <ItemIcon />
                        <ItemContent>
                            <ItemTitle>2. Monthly chat with Care Manager</ItemTitle>
                            <ItemDescription>Meet monthly with your Care Manager to discuss progress &amp; learn new skills, like mindfulness.</ItemDescription>
                        </ItemContent>
                    </GettingStartedItem>
                    <GettingStartedItem>
                        <ItemIcon />
                        <ItemContent>
                            <ItemTitle>3. Check in with your provider</ItemTitle>
                            <ItemDescription>Video chat with your provider to make sure everything is on track.</ItemDescription>
                        </ItemContent>
                    </GettingStartedItem>
                </EveryMonthList>
            </Diagram>
        </Container>
        <Waves />
    </Wrapper>
}