import React from 'react'
import styled from 'styled-components'
import Link from '../ui/Link'
import logoImage from '../../images/logo.svg'

const Wrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
`
const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(165.96deg, #D2EFF6 3.32%, #D4DBF5 97.9%);
    padding: 0 15px;
    height: 44px;

    @media(min-width: 600px){
        padding: 0 30px;
    }

    @media(min-width: 834px) {
        padding: 0 50px;
        height: 80px;
    }
`

const Logo = styled(Link)`
    width: 112px;
    height: 25px;
    background-size: cover;
    background-image: url(${logoImage});

    @media (min-width: 834px) {
        width: 178px;
        height: 40px;
    }

    img {
        height: 100%;
        width: 100%;
    }
`

export default function BusinessNavigation() {
    return <>
        <Wrapper>
            <Nav>
                <Logo to="/" />
            </Nav>
        </Wrapper>
    </>
}