import styled from 'styled-components'

const HollowButton = styled.a`
    color: #6786FF;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 30px;
    border: 2px solid #6786FF;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;

    &:active {
        color: #FFFFFF;
        background: linear-gradient(90deg, #85ABEE 6.2%, #6786FF 100%);
        box-shadow: inset 0px 7px 14px rgba(34, 34, 52, 0.13);
    }
`

export default HollowButton