import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;

    & > svg {
        transform: rotate( ${({ flip }) => flip ? `180deg` : `0`}) scaleX(2)  translateX(${({ translate }) => translate}%) translateY(5px);
    }
`

export default function Waves({ flip } = { flip: false }) {

    const wrapper = useRef()
    const [translate, setTranslate] = useState(0)

    useEffect(() => {

        function onScroll(e) {

            const rect = wrapper.current.getBoundingClientRect()

            if (rect.top > 0 && rect.top < window.innerHeight) {

                setTranslate((wrapper.current.getBoundingClientRect().top * 50) / window.innerHeight - 25)
            }
        }

        window.addEventListener('scroll', onScroll)

        return function cleanup() {

            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    return <Wrapper translate={translate} flip={flip} ref={wrapper}>
        <svg viewBox="0 0 1280 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M751.751 88.3129C1137.21 -76.1648 1305.49 40.2433 1305.49 40.2433L1319.86 602.081H1320.94V644.404L1321 646.814H1320.94V1642H-42V602.081H-26.9912L-26.8773 65.2364C-26.8773 65.2364 366.293 252.791 751.751 88.3129Z" fill="#fff"></path>
        </svg>
    </Wrapper>

}