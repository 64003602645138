import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'
import Link from '../ui/Link'
import Img from "gatsby-image"
import eyeImage from '../../images/eye.svg'


const Wrapper = styled.div`
    width: 200px;
    text-align: center;
    user-select: none;
    & ${Avatar} {
        user-select: none;   
        user-drag: none;
        box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
    }
`
const Name = styled.div`
    color: #250044;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 0;
`
const Description = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #250044;
    margin: 10px 0 0;
`
const BioLink = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        color: #6786FF;
        margin: 7px;
    }

    a {
        font-size: 14px;
        color: #6786FF;
        text-decoration: none;
    }
`
const AvatarImage = styled(Img)`
    border-radius: 100%;
    height: 200px;
`

export default function CareTeamCard({ name, title, profile, background, bio }) {

    return <Wrapper>
        <AvatarImage fixed={profile} backgroundColor={background} style={{ backgroundColor: background }} draggable={false} alt="avatar" />
        <Name>{name}</Name>
        <Description>{title}</Description>
        <BioLink><img src={eyeImage} alt="eye icon" /> <Link to={`${bio}`}>View Bio &rarr;</Link></BioLink>
    </Wrapper>
}