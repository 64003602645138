import React from "react"
import styled from "styled-components"
import Container from "../ui/Container"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Title = styled.h1`
  font-style: italic;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #FFFFFF;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`
const SubTitle = styled.h3`
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.375px;
  margin-top: 15px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`

const Texts = styled.div`
  text-align: center;

  @media(min-width: 600px) {
    margin: 0 0 0 30px;
    padding: 70px 0 100px;
    width: 45%;
    text-align: left;
  }
`

const GirlImg = styled(Img)`
  position: absolute;
`

const GirlWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  clip-path: ellipse(58% 72% at 50% 72%);
  margin: 50px auto 0px;

  & > div {
    position: absolute !important; 
    height: unset !important; 
    width: unset !important; 
    min-width: 100%; 
    min-height: 100%;
  }

  picture img {
    object-position: center top !important;
  }

  @media(min-width: 600px) {
    margin: 0;
    position: absolute;
    padding-bottom: unset;
    height: 100%;
    right: 0;
    top: 0;
    width: 50%;
    clip-path: ellipse(59% 65% at 65% 50%); 

    picture img {
      object-position: left center !important;
    }
  }
`

const Wrapper = styled.header`
  background: linear-gradient(190deg, #8BA3FF 19.5%, #A8BAFF 72.7%);
  position: relative;
  padding-top: 40px;

  @media(min-width: 834px) {
    background: linear-gradient(190deg, #8BA3FF 0%, #A8BAFF 100%);

    ${Title} {
      font-size: 40px;
      line-height: 55px;
    }

    ${SubTitle} {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }
`

const BusinessHeader = () => {

  const { girlMobile, girlDesktop } = useStaticQuery(graphql`
  query {
    girlMobile: file(relativePath: { eq: "girl-pillow.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 828) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    girlDesktop: file(relativePath: { eq: "girl-pillow-wide.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 1440 ) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`)

  const girls = [
    girlMobile.childImageSharp.fixed,
    {
      ...girlDesktop.childImageSharp.fixed,
      media: `(min-width: 834px)`,
    },
  ]

  return <Wrapper>
    <Container>
      <Texts>
        <Title>
          The Mental Health Benefit for Your Workforce
        </Title>
        <SubTitle>
          Cerebral helps your employees take control of anxiety &amp; depression. Online provider visits, care management, prescriptions, and delivery included.
      </SubTitle>
      </Texts>
    </Container>
    <GirlWrapper>
      <GirlImg fixed={girls} />
    </GirlWrapper>
  </Wrapper>
}

export default BusinessHeader
