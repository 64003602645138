import React from 'react'
import Container from '../ui/Container'
import ItalicHeading from '../ui/ItalicHeading'
import TestimonialList from '../ui/TestimonialList'
import Testimonial from '../ui/Testimonial'
import SectionWrapper from '../ui/SectionWrapper'
import styled from 'styled-components'
import Button from '../ui/Button'

const Wrapper = styled(SectionWrapper)`
    text-align: center;

    ${TestimonialList} {
        margin-top: 100px;
    }

    ${Testimonial}:nth-child(n+4){
        display: none;
    }

    ${Button} {
        margin: 52px auto 0;
    }

    @media(min-width: 834px) {

        ${Testimonial}:nth-child(n+4){
            display: inline-block;
        }

        ${Button} {
            margin: 72px auto 0;
        }
    }
`

export default function Testimonials({ testimonials, className }) {

    return <Wrapper className={className}>
        <Container>
            <ItalicHeading>Don’t just take our</ItalicHeading>
            <ItalicHeading>word for it.</ItalicHeading>
            <TestimonialList testimonials={testimonials} />
            <Button to="/testimonials">See More</Button>
        </Container>
    </Wrapper>
}