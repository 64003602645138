import React from 'react'
import ItalicHeading from '../ui/ItalicHeading'
import styled from 'styled-components'
import Carousel from '../ui/Carousel'
import SectionWrapper from '../ui/SectionWrapper'
import Button from '../ui/Button'
import Waves from '../ui/Waves'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from 'gatsby'

const BigAvatar = styled(Img)`
    position: absolute;
    height: 220px;
    width: 220px;
    background: #D4DBF5;
    box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
    border-radius: 100%;

    @media(min-width: 600px) {
        width: 334px;
        height: 334px;
    }
`
const SmallAvatar = styled(Img)`
    position: absolute;
    height: 130px;
    width: 130px;
    background: #85ABEE;
    box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
    border-radius: 100%;

    @media(min-width: 600px) {
        width: 200px;
        height: 200px;
    }
`

const Avatars = styled.div`
    position: relative;
    height: 242px;
    max-width: 299px;
    margin: 0 auto;
    text-align: left;

    & ${SmallAvatar} {
        right: 0;
        bottom: 0;
    }

    @media(min-width: 600px) {
        max-width: 460px;
        height: 368px;
    }
`
const Description = styled.p`
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #250044;
    margin: 30px auto 0;
    width: 90%; 
`
const FirstSection = styled.div`
`
const SecondSection = styled.div`
    margin: 40px 0 0;
`
const Sections = styled.div`
    margin: 100px 0 0;
    @media(min-width: 834px) {
        display: flex;

        & ${FirstSection} {
            width: 50%;
        }

        & ${SecondSection} {
            width: 50%;
        }

        & ${ItalicHeading} {
            text-align: left;
            margin-left: 0;
        }

        & ${Description} {
            text-align: left;
            margin-left: 0;
            font-size: 18px;
            line-height: 30px;
        }
    }
`
const GetStartedButton = styled(Button)`
    margin: 70px auto 120px;
    padding: 20px 73px;
`
const Wrapper = styled(SectionWrapper)`
    background: linear-gradient(134.18deg, rgba(212, 219, 245, 0.69) 0%, #EFF1FA 100%);
    text-align: center;

    ${Carousel} {
        margin: 50px auto 0;
    }

    @media (min-width: 600px) {
        ${GetStartedButton} {
            margin-bottom: 60px;
        }
    }

    @media(min-width: 834px) {

        ${Description} {
            font-size: 20px;
            line-height: 30px;
        }

        ${Carousel}  {
            margin-top: 65px;
        }
    }
`

export default function BusinessMeetCareTeam({ providers }) {

    const { big, small } = useStaticQuery(graphql`
    query {
      big: file(relativePath: { eq: "Anh.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      small: file(relativePath: { eq: "Gidget.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const updatedProviders = providers.map(p => ({ ...p, bio: `https://getcerebral.com/${p.bio}` }))

    return <Wrapper>
        <Waves flip={true} />
        <Sections>
            <FirstSection>
                <Avatars>
                    <BigAvatar fluid={big.childImageSharp.fluid} style={{ position: "absolute" }} />
                    <SmallAvatar fluid={small.childImageSharp.fluid} style={{ position: "absolute" }} />
                </Avatars>
            </FirstSection>
            <SecondSection>
                <ItalicHeading>Meet your Cerebral</ItalicHeading>
                <ItalicHeading>Care Team.</ItalicHeading>
                <Description>Everyone deserves access to personalized, non-judgmental mental health care. With Cerebral, your employees get access to an empathetic care team that listens, understands, and—when appropriate—is licensed to prescribe the best medication for their specific needs.</Description>
            </SecondSection>
        </Sections>

        <Carousel team={updatedProviders} />

        <Waves />
    </Wrapper>
}