import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '../ui/Container'
import SectionWrapper from '../ui/SectionWrapper'
import SecondaryHeading from '../ui/SecondaryHeading'
import QuoteImage from '../../images/quote.png'

const features = [

    {
        url: 'https://www.bloomberg.com/news/articles/2020-01-08/startup-gambles-on-treating-mental-health-online',
        quote: 'As simple as ordering a ride to the airport on your phone.',
        logo: 'bloomberg.svg',
    },
    {
        url: 'https://cheddar.com/media/cerebral-is-a-startup-trying-to-close-the-gap-of-mental-health-treatment',
        quote: 'Closing the gap in access to high-quality mental health care.',
        logo: 'cheddar.svg',
    },
    {
        url: 'http://finance.dailyherald.com/dailyherald/news/read/39393216/Cerebral_Launches_in_Seven_States',
        quote: 'A new alternative to a broken traditional mental health care system.',
        logo: 'dailyherald.svg',
    },
    {
        url: 'https://www.abc-7.com/story/41589172/cerebral-launches-in-seven-states-aiming-to-fill-a-gap-in-mental-health-care',
        quote: 'Reimagining the process by which people are treated for anxiety and depression.',
        logo: 'abc.svg',
    },
]

const Brands = styled.ul`
    margin: 100px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BrandItem = styled.li`
    margin: 0;
    display: ${({ active }) => active ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;

    & > a {
        display: block;
        height: 100%;
    }
`

const Logo = styled.img`
    height: 40px;
    opacity: ${({ active }) => active ? '1' : '.3'};
`

const QuoteCharacter = styled.img`
    margin: 70px auto 0;
    display: block;
    width: 68px;
    height: 58px;
`
const QuoteContainer = styled.div`
    min-height: 200px;
`
const Quote = styled.p`
    font-family: 'EB Garamond', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
    text-align: center;
    color: #250044;
    margin: 50px auto 0;

    @media(min-width: 834px) {
        width: 50%;
    }
`
const Wrapper = styled(SectionWrapper)`

    @media (min-width: 834px) {
        ${BrandItem} {
            display: flex;
        }

        ${Brands} {
            justify-content: space-around;
        }
    }
`

const picture = require.context(`./../../images/featured/`, false, /^.*\.(svg)$/)

export default function FeaturedIn() {

    const [current, setCurrent] = useState(0)

    useEffect(() => {

        const handler = setInterval(() => {

            setCurrent(c => c + 1)

        }, 3000)

        return function cleanup() {

            clearInterval(handler)
        }
    }, [])

    return <Wrapper>
        <Container>
            <SecondaryHeading>Featured In</SecondaryHeading>
            <Brands>
                {features.map((f, i) => {

                    const active = i === current % features.length

                    return <BrandItem active={active} key={i}>
                        <a href={f.url} target="_blank" rel="noopener noreferrer">
                            <Logo src={picture(`./${f.logo}`)} active={active} />
                        </a>
                    </BrandItem>
                })}
            </Brands>
            <QuoteCharacter src={QuoteImage} />
            <QuoteContainer>
                <Quote>{features[current % features.length].quote}</Quote>
            </QuoteContainer>
        </Container>

    </Wrapper>
}