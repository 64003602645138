import React from "react"
import BusinessLayout from "../components/BusinessLayout"
import SEO from "../components/seo"
import BusinessHeader from "../components/sections/BusinessHeader"
import BusinessWhatWeOffer from "../components/sections/BusinessWhatWeOffer"
import Testimonials from "../components/sections/Testimonials"
import BusinessMeetCareTeam from "../components/sections/BusinessMeetCareTeam"
import FeaturedIn from "../components/sections/FeaturedIn"
import BusinessCta from "../components/sections/BusinessCta"
import BusinessHowCerebralWorks from "../components/sections/BusinessHowCerebralWorks"
import { edgeToTestimonial, edgeToProvider } from "../lib/utils"
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {

  const testimonials = data.testimonials.edges.map(edge => edgeToTestimonial({ edge }))
  const providers = data.providers.edges.map(edge => edgeToProvider({ edge }))

  return <BusinessLayout>
    <SEO title="Home" />
    <BusinessHeader />
    <BusinessWhatWeOffer />
    <BusinessHowCerebralWorks />
    <Testimonials testimonials={testimonials} />
    <BusinessMeetCareTeam providers={providers} />
    <FeaturedIn />
    <BusinessCta />
  </BusinessLayout>
}

export const query = graphql`
  query BusinessHomeTemplateQuery {
    testimonials: allPrismicTestimonial(limit: 6, sort: {fields: data___order}) {
      edges {
        node {
          data {
            name {
              text
            }
            location {
              text
            }
            text {
              html
            }
            date {
              text
            }
            avatarnumber
            source
          }
        }
      }
    }
    providers: allPrismicProvider(sort: {fields: data___order}) {
      edges {
        node {
          data{
            name{
              text
            }
            title {
              text
            }
            profile {
              localFile {
                childImageSharp {
                  fixed(quality: 70, width: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            order
          }
          uid
        }
      }
    }
  }
`

export default IndexPage