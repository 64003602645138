import styled from 'styled-components'

const SectionWrapper = styled.div`
    margin: 100px 0 0;
    
    @media (min-width: 834px) {
        margin: 140px 0 0;
    }
`

export default SectionWrapper