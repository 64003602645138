import React from 'react'
import styled from 'styled-components'
import Container from '../ui/Container'
import ItalicHeading from '../ui/ItalicHeading'
import HollowButton from '../ui/HollowButton'
import SectionWrapper from '../ui/SectionWrapper'
import NotesImage from '../../images/notes.png'

const Description = styled.p`
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #250044;
    margin: 40px auto 0;
    font-weight: normal;
`
const NotSure = styled.p`
    font-weight: 600;
    font-size: 40px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #250044;
    margin: 60px auto 0;
`
const FirstSection = styled.div`

    & > ${HollowButton} {
        margin: 45px auto 0;
    }
`
const SecondSection = styled.div`
    margin: 73px auto 0;
`
const Notes = styled.img`
    display: block;
    margin: 0 auto;
    width: 200px;
`
const NotesDescription = styled.div`
    font-size: 30px;
    line-height: 60px;
    color: #6786FF;
    text-align: center;
    margin: 20px auto 0;
`

const StrikeThrough = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-decoration-line: line-through;
    color: #85ABEE;
`

const FirstMonth = styled.div`
    margin: 10px auto 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
`

const Bullet = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    &:first-child {
        margin: 36px auto 0;
    }
`

const Wrapper = styled(SectionWrapper)`
    text-align: center;

    ${ItalicHeading} {
        font-size: 26px;
    }
    
    @media(min-width: 375px) {

        ${ItalicHeading} {
            font-size: 28px;
        }
    }

    @media(min-width: 600px) {

        ${Notes} {
            width: 300px;
        }

        ${StrikeThrough} {
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
        }

        ${FirstMonth} {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }

        ${Bullet} {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
        }
        
        ${ItalicHeading} {
            font-size: 40px;
        }
    }
    
    @media (min-width: 834px) {
        display: flex;
        text-align: left;

        & ${FirstSection} {
            flex: 1;
        }

        & ${SecondSection} {
            margin-top: 40px;
            flex: 1;
        }

        & ${Description} {
            width: 100%;
            text-align: left;
            font-size: 20px;
            line-height: 30px;
        }

        & ${ItalicHeading} {
            font-size: 40px;
            margin-left: 0;
            margin-right: 0;
        }

        & ${NotSure} {
            text-align: left;
        }

        & ${HollowButton} {
            margin-left: 0;
            margin-right: 0;
        }
    }
`

export default function BusinessWhatWeOffer() {
    return <Container>
        <Wrapper>
            <FirstSection>
                <ItalicHeading>One plan for</ItalicHeading>
                <ItalicHeading>better mental health</ItalicHeading>
                <Description>Regular assessments, video appointments with your provider, ongoing Care Manager check-ins, prescriptions, and medication delivery are all included in the price of your subscription.</Description>
            </FirstSection>
            <SecondSection>
                <Notes src={NotesImage} />
            </SecondSection>
        </Wrapper>
    </Container>
}