import React, { useRef, useState } from 'react'
import CareTeamCard from './CareTeamCard'
import styled from 'styled-components'

const Slides = styled.div`
    display: flex;
    overflow-x: scroll;
    cursor: ${({ active }) => active ? 'grabbing' : 'grab'};
    
    &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
`
const Slide = styled.div`
    padding: 0 30px;
`

const Wrapper = styled.div`
    overflow: hidden;
`

const background = (i) => ['#D4DBF5', '#85ABEE', '#D2EFF6'][i % 3]

function Carousel({ team, className }) {

    const sliderRef = useRef()
    const [isDown, setIsDown] = useState(false)
    const startX = useRef()
    const scrollLeft = useRef()

    const onMouseDown = e => {

        setIsDown(true)
        startX.current = e.pageX - sliderRef.current.offsetLeft
        scrollLeft.current = sliderRef.current.scrollLeft;
    }

    const onMouseLeave = () => {

        setIsDown(false)
    }

    const onMouseUp = () => {

        setIsDown(false)
    }

    const onMouseMove = e => {

        if (isDown) {

            e.preventDefault()

            const x = e.pageX - sliderRef.current.offsetLeft;
            const walk = (x - startX.current);

            sliderRef.current.scrollLeft = scrollLeft.current - walk;
        }
    }

    return <Wrapper className={className}>

        <Slides ref={sliderRef} active={isDown} onMouseDown={onMouseDown} onMouseLeave={onMouseLeave} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
            {team.map((t, i) => <Slide key={i} ><CareTeamCard {...t} background={background(i)} /></Slide>)}
        </Slides>
    </Wrapper>
}

const StyledCarousel = styled(Carousel)``

export default StyledCarousel