import React from "react"
import BusinessNavigation from "./ui/BusinessNavigation"

import './reset.css'
import './layout.css'

function Layout({ children }) {

  return <>
    <BusinessNavigation />
    {children}
  </>
}

export default Layout
